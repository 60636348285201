<template>
  <div class="loginContainer" @contextmenu="preventRightClick">
    <!-- 左区域 -->
    <div class="loginLeftContainer">
      <img class="logoImg" src="@/assets/imgs/logo.png" />
    </div>
    <!-- 右区域 -->
    <div class="loginRightContainer">
      <!-- 工具栏 -->
      <div class="navContainer">
        <img class="navImg" src="@/assets/imgs/min.png" @click="minsize" />
        <img class="navImg" v-if="togglemaxsizeFlag == 'normal'" src="@/assets/imgs/max.png" @click="togglemaxsize" />
        <img class="navImg" v-else src="@/assets/imgs/normal.png" @click="togglemaxsize" />
        <img class="navImg" src="@/assets/imgs/close.png" @click="close" />
      </div>
      <!-- 登录 -->
      <div class="bodyContainer" v-if="loginOrRegister == 'login'">
        <!-- 登录类型 -->
        <div class="loginTypeContainer">
          <div :class="loginType == 'password' ? 'loginTypeItemSelect' : 'loginTypeItem'"
            @click="loginTypeChange('password')">账号密码登录</div>
          <div :class="loginType == 'code' ? 'loginTypeItemSelect' : 'loginTypeItem'" @click="loginTypeChange('code')">
            验证码登录</div>
        </div>
        <!-- 账号密码登录 -->
        <div class="formContainer" v-if="loginType == 'password'">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
            <el-form-item prop="username">
              <el-input style="width: 265px;" v-model="dataForm.username" placeholder="手机号/用户名/邮箱"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input style="width: 265px;" v-model="dataForm.password" placeholder="密码" show-password></el-input>
            </el-form-item>
            <div class="loginBtn" @click="login">登录</div>
            <!-- <div class="loginBtn" @click="syncData">同步数据</div> -->
            <div class="formBottomContainer">
              <el-checkbox v-model="rememberPwd">记住账号密码</el-checkbox>
              <div class="registerText" @click="loginOrRegisterChange('register')">立即注册</div>
            </div>
          </el-form>
        </div>
        <!-- 验证码登录 -->
        <div class="formContainer" v-if="loginType == 'code'">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
            <el-form-item prop="phone">
              <el-input style="width: 265px;" v-model="dataForm.phone" placeholder="手机号码"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input style="width: 165px;" v-model="dataForm.code" placeholder="验证码"></el-input>
              <span class="getCodeText" v-if="getCodeCountdown <= 0" @click="getCode">获取验证码</span>
              <span class="getCodeText" v-else style="cursor:not-allowed;">{{ this.getCodeCountdown }}s</span>
            </el-form-item>
            <div class="loginBtn" @click="login">
              登录
            </div>
            <div class="formBottomContainer">
              <div></div>
              <div class="registerText" @click="loginOrRegisterChange('register')">立即注册</div>
            </div>
          </el-form>
        </div>
      </div>
      <!-- 注册 -->
      <div class="bodyContainer" v-if="loginOrRegister == 'register'">
        <div style="width: 265px;">
          <div class="registerTitle">
            欢迎注册爱复盘
          </div>
          <div class="registerSubTitleContainer">
            <span>已有账号？</span>
            <span style="color: #0077FF; cursor: pointer;" @click="loginOrRegisterChange('login')">登录</span>
          </div>

          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" style="margin-top: 24px;">
            <el-form-item prop="username">
              <el-input style="width: 265px;" v-model="dataForm.username" placeholder="请设置用户名"></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input style="width: 265px;" v-model="dataForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input style="width: 265px;" v-model="dataForm.password" placeholder="密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input style="width: 165px;" v-model="dataForm.code" placeholder="验证码"></el-input>
              <span class="getCodeText" v-if="getCodeCountdown <= 0" @click="getCode">获取验证码</span>
              <span class="getCodeText" v-else style="cursor:not-allowed;">{{ this.getCodeCountdown }}s</span>
            </el-form-item>
            <div class="loginBtn" @click="register">
              注册
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginOrRegister: "login",
      loginType: "password",
      getCodeCountdown: 0,
      rememberPwd: false,
      getCodeInterval: null,
      dataForm: {
        username: "",
        password: "",
        phone: "",
        code: "",
      },
      dataRule: {
        username: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
          {
            min: 4,
            max: 20,
            message: "长度在 4 到 20 个字符",
            trigger: "blur",
          }
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            min: 6,
            message: "长度在 6 个字符以上",
            trigger: "blur",
          }
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          }
        ],
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          }, {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请填写正确的手机号码",
            trigger: "blur",
          }
        ],
      },
      togglemaxsizeFlag: 'normal'
    };
  },

  created() {
    // 禁止用户按F5和F12
    window.addEventListener("keydown", (event) => {
      if (event.key === 'F12' || event.key === 'F5') {
        event.preventDefault()
      }
    });

    let username = localStorage.getItem("username");
    if (username) {
      this.dataForm.username = username;
      this.dataForm.password = localStorage.getItem("password");
      this.rememberPwd = true;
    }

    // 用户被冻结跳到的登录页
    // if (localStorage.getItem("stopRecord")) {
    //   localStorage.setItem("stopRecord", "");
    //   this.$message.error("账号已被冻结，将停止录制和分析。");
    //   this.$httpClient.video.stopAutoAnalysis().then((res) => {
    //     console.log("停止自动分析")
    //     this.$httpClient.compere.stopdecector({}).then((res) => {
    //       if (res.code == 0) {
    //         this.$store.commit("saveDetectionStatus", false);
    //         this.$store.commit("saveDetectionTime", null);
    //       }
    //     });
    //   });
    // }
  },

  methods: {
    // 禁止鼠标右键
    preventRightClick(event) {
      event.preventDefault();
    },
    // 最大化/恢复正常
    togglemaxsize() {
      this.$httpClient.form.togglemaxsize().then((res) => {
        if (res.code == 0) {
          if (this.togglemaxsizeFlag == 'normal') {
            this.togglemaxsizeFlag = 'max';
          } else {
            this.togglemaxsizeFlag = 'normal';
          }
        }
      })
    },
    // 最小化
    minsize() {
      this.$httpClient.form.minsize().then((res) => {
        console.log("最小化")
      })
    },
    // 关闭
    close() {
      this.$httpClient.form.close().then((res) => {
        console.log("关闭")
      })
    },
    // 同步数据
    syncData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$httpBack.user.login(this.dataForm).then(res => {
            this.$httpClient.syncData.syncData({ token: res.data.token }).then((res) => {
              if (res.code == 0) {
                console.log("同步成功");
              }
            })
          })

        }
      });
    },
    // 登录
    login() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.rememberPwd) {
            localStorage.setItem("username", this.dataForm.username);
            localStorage.setItem("password", this.dataForm.password);
          } else {
            localStorage.setItem("username", "");
            localStorage.setItem("password", "");
          }

          this.$httpBack.user.login(this.dataForm).then(res => {
            if (res.code == 0) {

              this.$message.success(res.msg);

              this.$store.commit("saveLoginResultData", res.data);

              this.$httpClient.setup.settoken({ token: res.data.token, userId: res.data.id }).then((res) => {
                if (res.code == 0) {
                  this.$router.push({ path: "home" });
                }
              })

            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 注册
    register() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$httpBack.user.register(this.dataForm).then(res => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.loginOrRegister = "login";
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 登录注册切换回调
    loginOrRegisterChange(loginOrRegister) {
      this.$nextTick(() => {
        this.initDataForm();
        this.loginOrRegister = loginOrRegister;

        if (this.loginOrRegister == "login") {
          this.loginType = "password";
          let username = localStorage.getItem("username");
          if (username) {
            this.dataForm.username = username;
            this.dataForm.password = localStorage.getItem("password");
            this.rememberPwd = true;
          }
        }
      });
    },
    // 登录类型切换回调
    loginTypeChange(loginType) {

      this.initDataForm();

      this.$nextTick(() => {

        this.loginType = loginType;
        if (this.loginType == "password") {
          let username = localStorage.getItem("username");
          if (username) {
            this.dataForm.username = username;
            this.dataForm.password = localStorage.getItem("password");
            this.rememberPwd = true;
          }
        } else {
          this.initDataForm();
        }

      });
    },
    initDataForm() {
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        username: "",
        password: "",
        phone: "",
        code: "",
      }
    },
    // 获取验证码
    getCode() {
      if (this.getCodeCountdown > 0) {
        return;
      }

      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!this.dataForm.phone || !reg_tel.test(this.dataForm.phone)) {
        this.$message.error("请填写正确的手机号");
        return;
      }

      this.$httpBack.user.getPhoneCode({ phone: this.dataForm.phone }).then(res => {
        if (res.code == 0) {
          this.$message.success("获取成功，请留意短信");
        }
      });

      this.getCodeCountdown = 60;
      // 启动定时器
      this.getCodeInterval = setInterval(() => {
        // 创建定时器，每1秒执行一次
        this.getCodeCountdown -= 1;
        if (this.getCodeCountdown <= 0) {
          clearInterval(this.getCodeInterval);
          this.getCodeInterval = null;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.getCodeText {
  margin-left: 10px;
  width: 90px;
  font-size: 14px;
  color: #2E3742;
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #DCE0E7;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  box-sizing: border-box;
}

.registerSubTitleContainer {
  font-size: 14px;
  color: #2E3742;
}

.registerTitle {
  font-weight: 600;
  font-size: 20px;
  color: #2E3742;
}

/* .getCodeText {
  color: #555;
  cursor: pointer;
  border: 0.5px solid #ccc;
  padding: 2px 6px;
  border-radius: 4px;
  background: #FFF;
} */
.registerText {
  font-size: 14px;
  color: #0077FF;
  cursor: pointer;
}

.formBottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.loginBtn {
  width: 265px;
  height: 44px;
  background: #0077FF;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}

.formContainer {
  margin-top: 27px;
}

.loginTypeItemSelect {
  font-weight: 500;
  font-size: 16px;
  color: rgb(0, 119, 255);
  padding: 7px 0;
  height: 36px;
  border-bottom: 3px solid rgb(0, 119, 255);
  ;
  box-sizing: border-box;
  cursor: pointer;
}

.loginTypeItem {
  font-weight: 500;
  font-size: 16px;
  height: 36px;
  color: #2E3742;
  padding: 7px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.loginTypeContainer {
  width: 222px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  flex-grow: 1;
  justify-content: center;
  margin-top: -30px;
}

.navImg {
  margin-right: 26px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.navContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  z-index: 50;
}

.logoImg {
  margin-top: 40px;
  margin-left: 60px;
}

.loginRightContainer {
  background: #FFFFFF;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loginLeftContainer {
  /* background: #E0EFFF; */
  width: 60%;
  height: 100%;
  background-image: url(~@/assets/imgs/loginBack.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.loginContainer {
  display: flex;
  border: 1px solid #ccc;
  height: calc(100vh - 2px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
